import { Tooltip } from '@mantine/core';
import React, { FC, useMemo } from 'react';

import { UserPermissions } from '@portals/types';
import { AccessLevelEnum } from '@portals/utils';

import { usePermissionAccess } from './use-permission-access';

interface PermissionAccessProps {
  minAccessLevel: AccessLevelEnum;
  permissionKey: keyof UserPermissions;
  tooltipLabel?: string;
}

export const PermissionAccess: FC<PermissionAccessProps> = ({
  minAccessLevel,
  permissionKey,
  tooltipLabel = 'Missing permissions',
  children,
}) => {
  const { canView, canEdit } = usePermissionAccess();

  const hasPermission = useMemo(() => {
    switch (minAccessLevel) {
      case AccessLevelEnum.None:
        return true;
      case AccessLevelEnum.View:
        return canView(permissionKey);
      case AccessLevelEnum.Edit:
        return canEdit(permissionKey);
      default:
        return false;
    }
  }, [canEdit, canView, minAccessLevel, permissionKey]);

  if (hasPermission) {
    return <>{children}</>;
  }

  return (
    <>
      {React.Children.map(children, (child) => {
        return (
          React.isValidElement(child) && (
            <Tooltip
              label={tooltipLabel}
              withArrow
              styles={{ root: { display: 'block' } }}
            >
              {React.cloneElement(child, {
                'aria-disabled': true,
                style: {
                  ...child.props.style,
                  opacity: 0.5,
                  pointerEvents: 'none',
                  userSelect: 'none',
                },
              })}
            </Tooltip>
          )
        );
      })}
    </>
  );
};
