import { Box, Select, SelectProps } from '@mantine/core';
import React, { FC, useRef, useState } from 'react';

import { ReactComponent as SelectToggleActive } from '../../assets/select-toggle-active.svg';
import { ReactComponent as SelectToggle } from '../../assets/select-toggle.svg';

interface Option {
  value: string;
  label: string;
}

interface SelectCellProps {
  value: string;
  options: Array<Option | string>;
  onChange: (selectedOption: string) => void;
  selectProps?: Partial<SelectProps>;
}

export const SelectCell: FC<SelectCellProps> = ({
  value,
  selectProps = {},
  options,
  onChange,
}) => {
  const ref = useRef();
  const [isOpen, setIsOpen] = useState(false);

  // Issue with jump on open: https://github.com/mantinedev/mantine/issues/1680
  return (
    <Box
      sx={(theme) => ({
        width: `calc(100% + ${theme.spacing.md * 2}px)`,
        transform: `translateX(-${theme.spacing.md}px)`,
      })}
    >
      <Select
        onDropdownOpen={() => setIsOpen(true)}
        onDropdownClose={() => setIsOpen(false)}
        variant="unstyled"
        value={value}
        data={options}
        onChange={onChange}
        ref={ref}
        rightSection={isOpen ? <SelectToggleActive /> : <SelectToggle />}
        styles={(theme) => ({
          rightSection: {
            pointerEvents: 'none',
            paddingRight: theme.spacing.md,
          },
        })}
        {...selectProps}
      />
    </Box>
  );
};
