import { Avatar, MantineSize } from '@mantine/core';
import {
  first,
  join,
  last,
  map,
  random,
  size,
  upperCase,
  words,
} from 'lodash/fp';
import React, { FC, useMemo } from 'react';

const AVATAR_COLORS = ['red', 'orange', 'blue', 'indigo', 'green'];

interface OrganizationAvatarProps {
  name: string;
  size: MantineSize | number;
  radius: MantineSize | number;
}

export const NameAbbreviation: FC<OrganizationAvatarProps> = ({
  name,
  size: avatarSize,
  radius,
}) => {
  const color = useMemo(() => {
    const colorIndex = random(0, 4);
    return AVATAR_COLORS[colorIndex];
  }, []);

  const nameAbbreviation = useMemo(() => {
    const nameWords = words(name);

    if (size(nameWords) === 1) {
      return upperCase(first(nameWords[0]));
    }

    return join(
      '',
      map((word) => upperCase(first(word)), [first(nameWords), last(nameWords)])
    );
  }, [name]);

  return (
    <Avatar color={color} size={avatarSize} radius={radius}>
      {nameAbbreviation}
    </Avatar>
  );
};
